//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @author Emmanuel Hernández
 * @copyright 2023, Marciano Studio S.C.
 * @version 1.1.0
 */
import Model from 'src/models/model-comunicado'
import MsDealerAutocomplete from 'components/_shared/ms-dealer-autocomplete.vue'
import { extend } from 'quasar'

export default {
  name: 'comunicado-page',
  components: {
    MsDealerAutocomplete
  },
  data() {
    return {
      model: new Model(),
      dealership: null,
      dialog: false,
      dialogCallSuzuki: false,
      isValid: true,
      options: [
        {
          label: 'Reparación SIN COSTO',
          value: '1'
        },
        {
          label: 'Compra de un SUZUKI nuevo',
          value: '2'
        }
      ]
    }
  },
  computed: {
    models() {
      return this.$store.getters['carmodels/itemsForm']
    }
  },
  mounted() {
    this.model.$rules.niv.push(() => this.isValid || 'Código VIN no encontrado')
  },
  methods: {
    async onSubmit() {
      try {
        if (!this.dealership) {
          this.$notify.error('Debes seleccionar un concesionario.')
          return
        }
        if (!this.model.noticePrivacy) {
          this.$notify.error(
            'Debes aceptar nuestra política de privacidad para continuar.'
          )
          return
        }
        if (this.model.year < 2017) {
          this.dialogCallSuzuki = true
          return
        }
        this.$q.loading.show()
        let data = extend(true, {}, this.model)
        delete data.$rules
        delete data.noticePrivacy
        data.dealershipId = this.dealership.id
        // const response = await this.$store.dispatch('forms/niv', data)
        // if (response.data.code == 'VALID_NIV') {
        await this.$store.dispatch('forms/replacement', data)
        this.dialog = true
        this.$refs.form.reset()
        // } else {
        //   this.$notify.error('Código VIN no encontrado.')
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.$q.loading.hide()
      }
    },
    onReset() {
      this.model.fullName = null
      this.model.email = null
      this.model.phoneNumber = null
      this.model.dealershipId = null
      this.model.year = null
      this.model.niv = null
      this.model.city = null
      this.model.type = null
      this.model.comments = null
      this.model.model = null
      this.model.noticePrivacy = false
    },
    async onBlur(evt) {
      if (evt.target.value.length == 17) {
        const response = await this.$store.dispatch('forms/niv', {
          niv: this.model.niv
        })
        this.isValid = response.data.code == 'VALID_NIV'
        this.model.model = this.isValid ? response.data.vehicle.model : null
        this.model.year = this.isValid ? response.data.vehicle.year : null
        this.$refs.ref_niv.validate()
      }
    },
    async onInput(val) {
      if (val.length == 17) {
        const response = await this.$store.dispatch('forms/niv', {
          niv: this.model.niv
        })
        this.isValid = response.data.code == 'VALID_NIV'
        this.model.model = this.isValid ? response.data.vehicle.model : null
        this.model.year = this.isValid ? response.data.vehicle.year : null
        this.$refs.ref_niv.validate()
      }
    }
  },
  preFetch({ store }) {
    return store.dispatch('carmodels/queryForm', {
      with:
        'versions.transmissions,versions,versions.colors,carImage,backgroundImage,nameImage,nameImageNegative,accessoriesFile',
      sort: 'priority asc',
      'filters[whereIn][status]': ['PUBLISHED', 'EXTERNAL'],
      'filters[whereNotIn][name]': [
        'GRAND-VITARA-BOOSTERGREEN',
        'FRONX-BOOSTERGREEN'
      ]
    })
  }
}
