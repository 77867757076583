//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ms-dealer-autocomplete',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      dealerships: [],
      radio: null,
      options: { componentRestrictions: { country: 'MX' } }
    }
  },
  computed: {
    show() {
      let d = new Date()
      return (
        this.model == 'CIAZ' &&
        ((d.getFullYear() == 2023 && d.getMonth() == 5 && d.getDate() == 30) ||
          (d.getFullYear() == 2023 && d.getMonth() == 6 && d.getDate() == 3))
      )
    },
    filteredDealers() {
      let _dealerships = JSON.parse(
        JSON.stringify(this.$store.getters['dealerships/items'])
      )
      if (!this.show) {
        _dealerships.forEach(d => {
          d.items = d.items.filter(i => i.id != 107)
        })
        return _dealerships
      }
      return this.dealerships
    }
  },
  methods: {
    onId(idxDealer, idxItems, dealership, arrayLength) {
      for (let i = 0; i < arrayLength; i++) {
        let a = document.getElementById('sucursal' + i)
        if (i == idxItems) {
          a.style.border = '2px solid #017fff'
        } else {
          a.style.border = '1px solid rgba(159, 169, 173, 0.1)'
        }
      }
      this.radio = dealership.id
      this.$emit('input', dealership)
    },
    geolocate() {
      if (navigator.geolocation !== undefined) {
        navigator.geolocation.getCurrentPosition(position => {
          this.getDealerships({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            limit: 1000
          })
        })
      }
    },
    updatePlace(place) {
      this.getDealerships({
        latitude: place.geometry ? place.geometry.location.lat() : null,
        longitude: place.geometry ? place.geometry.location.lng() : null,
        limit: 1000
      })
    },
    async getDealerships(params) {
      let _dealerships = []
      this.$q.loading.show()
      await this.$store.dispatch('dealerships/queryLocation', params)
      _dealerships = JSON.parse(
        JSON.stringify(this.$store.getters['dealerships/items'])
      )
      this.dealerships = _dealerships
      this.$q.loading.hide()
    }
  }
}
