import FieldRules from './validators/field-rules.js'

export default () => {
  return {
    fullName: null,
    email: null,
    phoneNumber: null,
    dealershipId: null,
    year: null,
    niv: null,
    city: null,
    comments: null,
    noticePrivacy: false,
    model: null,
    type: null,
    $rules: {
      fullName: new FieldRules().required().get(),
      email: new FieldRules()
        .required()
        .email()
        .get(),
      phoneNumber: new FieldRules()
        .required()
        .maxLength(10)
        .minLength(10)
        .get(),
      year: new FieldRules().required().get(),
      niv: new FieldRules()
        .required()
        .maxLength(17)
        .minLength(17)
        .get(),
      city: new FieldRules().required().get(),
      model: new FieldRules().required().get(),
      type: new FieldRules().required().get(),
      comments: new FieldRules().required().get()
    }
  }
}
